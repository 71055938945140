



































































































































































































































































































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import {
  ApiBasicInfoCheckName,
  ApiBasicInformationSave,
  ApiGetllnessList,
  ApiGetProvince,
  ApiGetCity,
  ApiGetArea,
  ApiGetScaleNameSelectDoctor,
  ApiGetScaleNameSelectPatient,
} from "@/apis";
import { checkIdCard } from "@/utils";
import { breadcrumb } from "@/utils/breadcrumb";
import { Form } from "element-ui/types/element-ui";
import * as utils from "@/utils";

@Component({
  name: "HomeLiu",
})
export default class Home extends Vue {
  //用户姓名
  userName = "";
  //当前时间信息
  date: any = {
    year: "",
    month: "",
    date: "",
    day: "",
  };
  userInfo = {} as any;
  dialogAdd = false;
  currentAddr = [];
  caseId = null;

  homeland = ["中国", "澳大利亚", "新加坡"];

  basicInformationForm = {
    user_id: "",
    user_name: "",
    user_sex: "",
    user_tel: "",
    user_idtype: "",
    user_idcardnum: "",
    // user_birthday: "",
    age: "",
    user_education: "",
    user_height: "",
    user_weight: "",
    province_code: "",
    city_code: "",
    area_code: "",
    user_communicationaddr: "",
    disease_date: "",
    user_disease: "",
    user_censusAddr: "",
    user_country: "",
    user_nationality: "汉族",
    user_province: "",
    user_city: "",
    user_occupation: "",
    user_marriage: "",
  } as any;

  passportRule = [
    { required: false, message: "请输入证件号码", trigger: "blur" },
  ];
  idCardRule = [
    { required: false, message: "请输入证件号码", trigger: "blur" },
    {
      pattern:
        /^(^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$)|(^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])((\d{4})|\d{3}[Xx])$)$/,
      message: "请输入正确的身份证号",
      trigger: "blur",
    },
  ];
  sexList = [
    {
      label: "男",
      value: "男",
    },
    {
      label: "女",
      value: "女",
    },
  ];
  documentsList = [
    {
      label: "身份证",
      value: "身份证",
    },
    {
      label: "护照",
      value: "护照",
    },
  ];
  educationLevellist = [
    "未知",
    "本科",
    "研究生",
    "大专",
    "中专",
    "技校",
    "高中",
    "初中",
    "小学",
    "文盲",
  ];
  nation = ["汉族", "回族", "满族", "其它"];
  position = ["工人", "教师", "农民", "干部", "学生", "职员", "退休", "其它"];
  marry = ["未婚", "已婚", "初婚", "再婚", "复婚", "丧偶", "离婚", "未知"];
  currentIllnessList = [];
  currentAddressProps = {
    lazy: true,
    lazyLoad(node: any, resolve: any) {
      let result = [];
      let param = {};
      //获取当前层级
      const { level } = node;
      if (level == 0) {
        ApiGetProvince(param).then((res) => {
          if (res.success) {
            // code: "34"
            // first_letter: "A"
            // name: "安徽省"
            result = res.data.map((item: any) => {
              return {
                value: item.code,
                label: item.name,
              };
            });
            resolve(result);
          }
        });
      } else if (level == 1) {
        param = {
          province_code: node.value,
        };
        ApiGetCity(param).then((res) => {
          if (res.success) {
            // code: "3408"
            // first_letter: "A"
            // name: "安庆市"
            // province_code: "34"
            result = res.data.map((item: any) => {
              return {
                value: item.code,
                label: item.name,
              };
            });
            resolve(result);
          }
        });
      } else if (level == 2) {
        param = {
          city_code: node.value,
        };
        ApiGetArea(param).then((res) => {
          if (res.success) {
            // city_code: "3408"
            // code: "340871"
            // first_letter: "A"
            // name: "安徽安庆经济开发区"
            // province_code: "34"
            result = res.data.map((item: any) => {
              return {
                value: item.code,
                label: item.name,
                leaf: level >= 2,
              };
            });
            resolve(result);
          }
        });
      }
    },
  };
  basicInformationRules = {
    user_name: [{ required: true, message: "请输入患者姓名", trigger: "blur" }],
    user_sex: [{ required: true, message: "请选择患者性别", trigger: "blur" }],
    user_tel: [{ required: true, message: "请输入联系方式", trigger: "blur" }],
    age: [{ required: true, message: "请输入年龄", trigger: "blur" }],
    user_idcardnum: this.passportRule,
    disease_date: [
      { required: true, message: "请选择发病年份", trigger: "blur" },
    ],
    user_disease: [
      { required: true, message: "请选择当前所患疾病", trigger: "blur" },
    ],
  };

  dialogVisible = false;
  scaleSearchList = [];
  scaleAssessmentList = [];
  currentScaleID: undefined | number | string = "";
  searchForm: any = {
    currentScaleName: "",
  };
  rules = {
    currentScaleName: [
      { required: true, message: "请选择使用的量表", trigger: "change" },
    ],
  };

  saveBasicInformation() {
    const currentAddrList = this.currentAddr;
    const _this = this;
    if (currentAddrList.length == 3) {
      this.basicInformationForm.province_code = currentAddrList[0];
      this.basicInformationForm.city_code = currentAddrList[1];
      this.basicInformationForm.area_code = currentAddrList[2];
    } else {
      this.basicInformationForm.province_code = "";
      this.basicInformationForm.city_code = "";
      this.basicInformationForm.area_code = "";
    }
    const formRef = this.$refs.basicInformationRuleForm as any;
    formRef.validate((valid: boolean) => {
      if (valid) {
        // if (this.caseId) {
        //   this.basicInformationForm.user_id = this.caseId;
        // }
        const param = {
          ...this.basicInformationForm,
        };
        param.age = Number(param.age);
        param.user_disease = JSON.stringify(
          this.basicInformationForm.user_disease
        );
        //如果有填写证件号码，需要判断证件类型，没有证件号码直接提交
        if (this.basicInformationForm.user_idcardnum) {
          //如果证件类型是护照，不进行验证,直接调用新增基础信息接口
          if (this.basicInformationForm.user_idtype == "护照") {
            const par = {
              doctor_id: (utils.get("doctorLoginInfo") as any).id,
              user_name: this.basicInformationForm.user_name,
              user_id: this.basicInformationForm.user_id,
            };
            ApiBasicInfoCheckName(par).then((ress: any) => {
              if (!ress.data.duplication_name) {
                ApiBasicInformationSave(param).then((res) => {
                  if (res.success) {
                    this.caseId = res.data;
                    this.$message.success("保存成功！");
                    this.cancelFun();
                    breadcrumb.push(
                      this,
                      "/doctor-system/medical/list",
                      "患者列表",
                      null,
                      true
                    );
                  }
                });
              } else {
                this.$confirm("已有相同姓名的患者，确认保存吗？", "提示", {
                  confirmButtonText: "确定",
                  cancelButtonText: "取消",
                  type: "warning",
                }).then(async () => {
                  ApiBasicInformationSave(param).then((res) => {
                    if (res.success) {
                      this.caseId = res.data;
                      this.$message.success("保存成功！");
                      this.cancelFun();
                      breadcrumb.push(
                        this,
                        "/doctor-system/medical/list",
                        "患者列表",
                        null,
                        true
                      );
                    }
                  });
                });
              }
            });
          } else {
            //如果证件类型是身份证 校验身份证与姓名是否匹配
            checkIdCard(
              this.basicInformationForm.user_idcardnum,
              this.basicInformationForm.user_name,
              function (res: any) {
                if (res != "01") {
                  _this.$message.warning(res);
                  return;
                }
                const par = {
                  doctor_id: (utils.get("doctorLoginInfo") as any).id,
                  user_name: _this.basicInformationForm.user_name,
                  user_id: _this.basicInformationForm.user_id,
                };
                ApiBasicInfoCheckName(par).then((ress: any) => {
                  if (!ress.data.duplication_name) {
                    ApiBasicInformationSave(param).then((res) => {
                      if (res.success) {
                        _this.caseId = res.data;
                        _this.$message.success("保存成功！");
                        _this.cancelFun();
                        breadcrumb.push(
                          _this,
                          "/doctor-system/medical/list",
                          "患者列表",
                          null,
                          true
                        );
                      }
                    });
                  } else {
                    _this
                      .$confirm("已有相同姓名的患者，确认保存吗？", "提示", {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning",
                      })
                      .then(async () => {
                        ApiBasicInformationSave(param).then((res) => {
                          if (res.success) {
                            _this.caseId = res.data;
                            _this.$message.success("保存成功！");
                            _this.cancelFun();
                            breadcrumb.push(
                              _this,
                              "/doctor-system/medical/list",
                              "患者列表",
                              null,
                              true
                            );
                          }
                        });
                      });
                  }
                });
              }
            );
          }
        } else {
          const par = {
            doctor_id: (utils.get("doctorLoginInfo") as any).id,
            user_name: _this.basicInformationForm.user_name,
            user_id: _this.basicInformationForm.user_id,
          };
          ApiBasicInfoCheckName(par).then((ress: any) => {
            if (!ress.data.duplication_name) {
              ApiBasicInformationSave(param).then((res) => {
                if (res.success) {
                  _this.caseId = res.data;
                  localStorage.setItem("database_id", res.data);
                  //   _this.canUpload = true;
                  _this.cancelFun();
                  _this.$message.success("保存成功！");
                  breadcrumb.push(
                    _this,
                    "/doctor-system/medical/list",
                    "患者列表",
                    null,
                    true
                  );
                }
              });
            } else {
              _this
                .$confirm("已有相同姓名的患者，确认保存吗？", "提示", {
                  confirmButtonText: "确定",
                  cancelButtonText: "取消",
                  type: "warning",
                })
                .then(async () => {
                  ApiBasicInformationSave(param).then((res) => {
                    if (res.success) {
                      _this.caseId = res.data;
                      localStorage.setItem("database_id", res.data);
                      //   _this.canUpload = true;
                      _this.cancelFun();
                      _this.$message.success("保存成功！");
                      breadcrumb.push(
                        _this,
                        "/doctor-system/medical/list",
                        "患者列表",
                        null,
                        true
                      );
                    }
                  });
                });
            }
          });
        }
      }
    });
  }
  dealCard() {
    // const idCardNum = this.basicInformationForm.user_idcardnum;
    // if (
    //   idCardNum.length === 18 &&
    //   this.basicInformationForm.user_idtype === "身份证"
    // ) {
    //   const year = idCardNum.substring(6, 10);
    //   const month = idCardNum.substring(10, 12);
    //   const date = idCardNum.substring(12, 14);
    //   this.basicInformationForm.user_birthday = year + "-" + month + "-" + date;
    //   this.basicInformationForm.user_birthday = new Date(
    //     this.basicInformationForm.user_birthday
    //   ).getTime();
    // }
  }
  basicInformationSelectChange(content: string) {
    if (content == "身份证") {
      this.basicInformationRules.user_idcardnum = this.idCardRule as any;
    } else {
      this.basicInformationRules.user_idcardnum = this.passportRule;
    }
    this.basicInformationForm.user_idcardnum = "";
  }
  initCurrentIllnessList() {
    const param = {
      dc_typeid: 699,
    };
    ApiGetllnessList(param).then((res) => {
      if (res.success) {
        this.currentIllnessList = res.data;
      }
    });
  }
  async mounted() {
    //已登录用户信息获取
    this.userName = localStorage.getItem("userName") as string;
    this.userInfo = JSON.parse(localStorage.getItem("doctorLoginInfo") as any);
    //初始化当前日期信息
    this.initDate();
    this.initCurrentIllnessList();
    this.getScaleNameList(0);
  }
  //初始化当前时间
  initDate() {
    const date = new Date();
    this.date.year = date.getFullYear();
    this.date.month = ("0" + (date.getMonth() + 1)).slice(-2);
    this.date.date = ("0" + date.getDate()).slice(-2);
    this.date.day = date.getDay();
    const day = ["日", "一", "二", "三", "四", "五", "六"];
    this.date.day = day[this.date.day];
  }
  addDialogFun() {
    this.dialogAdd = true;
  }
  handleKeysBlank(val: any) {
    Object.keys(val).map((item: any) => {
      val[item] = "";
    });
  }
  cancelFun() {
    this.handleKeysBlank(this.basicInformationForm);
    this.basicInformationForm.user_nationality = "汉族";
    (this.$refs.basicInformationRuleForm as Form).clearValidate();
    this.currentAddr = [];
    this.dialogAdd = false;
  }
  formatDate(timeStamp: number | string | null) {
    if (timeStamp == null) {
      return "-";
    }
    const time = new Date(timeStamp);
    //年-月-日
    return `${time.getFullYear()}-${this.format(
      time.getMonth() + 1
    )}-${this.format(time.getDate())} ${this.format(
      time.getHours()
    )}:${this.format(time.getMinutes())}:${this.format(time.getSeconds())}`;
  }
  format(date: number | string) {
    date = date.toString();
    return ("0" + date).slice(-2);
  }
  provinceList = [] as any;
  cityList = [] as any;
  //获取省下拉数据
  resultprovinceList() {
    ApiGetProvince().then((res: any) => {
      if (res.success) {
        this.provinceList = res.data;
        console.log(this.provinceList);
      }
    });
  }
  //省份change事件
  nationChange(val: any) {
    if (this.basicInformationForm.user_country != "中国") return;
    let objNation = {} as any;
    objNation = this.provinceList.find((item: any) => {
      return item.code === val;
    });
    this.basicInformationForm.user_province = objNation.name;
    const province_id = objNation.code;
    ApiGetCity({ province_code: province_id }).then((res) => {
      this.cityList = res.data;
    });

    this.basicInformationForm.user_city = "";
  }
  //市change事件
  cityChange(val: any) {
    if (this.basicInformationForm.user_country != "中国") return;
    let objCity = {} as any;
    objCity = this.cityList.find((item: any) => {
      return item.code === val;
    });
    this.basicInformationForm.user_city = objCity.name;
  }
  // 点击清空省
  clearProvince() {
    this.basicInformationForm.user_city = "";
    this.basicInformationForm.user_province = "";
    // 设置市为空
    this.cityList = [];
  }
  // 点击清空市
  clearCity() {
    // 设置市为空
    this.basicInformationForm.user_city = "";
    // setTimeout(() => {
    //   // 添加事件队列
    //   const formRef11 = this.$refs["ruleForm"] as any;
    //   formRef11.clearValidate(["province"]);
    //   formRef11.clearValidate(["county"]);
    //   formRef11.clearValidate(["city"]);
    // }, 0);
  }
  countryChangeHandle() {
    if (this.basicInformationForm.user_country == "中国") {
      this.basicInformationForm.user_province = "";
      this.basicInformationForm.user_city = "";
      this.resultprovinceList();
    } else if (this.basicInformationForm.user_country == "澳大利亚") {
      this.basicInformationForm.user_province = "澳大利亚";
      this.basicInformationForm.user_city = "澳大利亚";
      this.provinceList = [
        {
          code: "100000080",
          first_letter: "A",
          name: "澳大利亚",
        },
      ];
      this.cityList = [
        {
          province_code: "100000080",
          code: "1000000801",
          first_letter: "A",
          name: "澳大利亚",
        },
      ];
    } else if (this.basicInformationForm.user_country == "新加坡") {
      this.basicInformationForm.user_province = "新加坡";
      this.basicInformationForm.user_city = "新加坡";
      this.provinceList = [
        {
          code: "100000081",
          first_letter: "X",
          name: "新加坡",
        },
      ];
      this.cityList = [
        {
          province_code: "100000081",
          code: "1000000811",
          first_letter: "X",
          name: "新加坡",
        },
      ];
    }
  }

  cancelGoTo() {
    this.dialogVisible = false;
    this.searchForm.currentScaleName = "";
    const p = this.$refs.ruleForm as any;
    p.resetFields();
  }
  //当选择的使用量表发生变化时触发的函数
  selectChange(data: number) {
    const item: any = this.scaleAssessmentList.find((item: any) => {
      return item.name == data;
    });
    if (item) {
      this.currentScaleID = item.module;
    }
  }

  searchFormChange(data: any) {
    this.searchForm.currentScaleName = data;
  }

  //量表评估-去评估按钮触发函数
  evaluate() {
    const p = this.$refs.ruleForm as any;
    p.validate((vaid: any) => {
      if (vaid) {
        this.dialogVisible = false;
        //需要给新增传两个内容:量表id和量表名称
        this.$router.push({
          path: "/doctor-system/elemedirecord/scaleAssessment/add",
          query: {
            scaleId: this.currentScaleID as any,
            scaleName: this.searchForm.currentScaleName,
            database_id: this.$route.query.user_id,
          },
        });
      }
    });
  }
  //获取量表评估-量表名称列表
  getScaleNameList(type: number) {
    switch (type) {
      case 0: {
        ApiGetScaleNameSelectDoctor().then((res) => {
          if (res.success) {
            this.scaleSearchList = res.data;
            this.scaleAssessmentList = res.data;
          }
        });
        break;
      }
      case 1: {
        ApiGetScaleNameSelectPatient().then((res) => {
          if (res.success) {
            this.scaleSearchList = res.data;
          }
        });
        break;
      }
    }
  }

  ageInputHandle() {
    if (isNaN(this.basicInformationForm.age)) {
      this.$message.error("请输入大于等于0的正整数。");
      this.basicInformationForm.age = "";
      return;
    }
    if (Number(this.basicInformationForm.age) < 0) {
      this.$message.error("请输入大于等于0的正整数。");
      this.basicInformationForm.age = "";
      return;
    }
  }
}
